<template>
  <section class="pdf-content">
    <full-calendar
      class="calendar-calendar"
      :options="calendarOptions">
      <template #eventContent="arg">
        <i>{{ arg.event.title }}</i>
      </template>
    </full-calendar>
  </section>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import FullCalendar from '@fullcalendar/vue';

export default {
  name: 'GetecmaPdfContentMonth',
  components: {
    FullCalendar,
  },
  props: {
    calendarOptions: {
      type: Object,
    },
  },
};
</script>

<style lang='scss' scoped>
  .pdf-content {
    width: 100%;
    height: 3970px;
    background: #fff;
  }
</style>

<style lang='scss'>
  .vue-html2pdf {
    .fc .fc-daygrid-event {
        font-size: 10px!important;
    }
    .fc .fc-daygrid-day-frame {
      height: 793px;
    }
  }
</style>

<template>
  <getecma-aside-content-layout v-loading="isLoading" :widht100="true">
    <template #aside>
      <div class="calendar">
        <div class="calendar-main">
          <getecma-breadcrumb :items="itemsBreadcrumb" class="mt--xl mb--xl" />
          <div class="d--flex justify-content--space-between align-items--center">
            <getecma-header class="mt--md">Coletas</getecma-header>
            <getecma-button v-if="isAdmin" @click="showGenerateCollectsModal()">
              Gerar Coletas
            </getecma-button>
          </div>
          <div class="row mt--xl mb--xl">
            <div class="form-field col-18 col-lg-9">
              <div>
                  <getecma-header size="sm"> Veículo*</getecma-header>
              </div>
              <getecma-select
                name="veículo*"
                placeholder="Selecione um veículo"
                :options="vehicles"
                @on-change="onVehicleChange">
              </getecma-select>
            </div>
          </div>
          <div v-if="vehicleId" class="row mt--xl mb--xl">
            <div class="form-field col-18 col-lg-9">
              <div class="row mt--xl mb--xl">
                <div class="form-field col-18 col-lg-9">
                  <getecma-header size="sm">Impressão</getecma-header>
                </div>
              </div>
              <div class="row mt--xl mb--xl">
                <div class="form-field col-10 col-lg-5">
                  <getecma-button @click="downloadPdfMonth()">
                    Mês Atual
                  </getecma-button>
                </div>
                <div class="form-field col-10 col-lg-5">
                  <getecma-button @click="downloadPdfDay()">
                    Dia Atual
                  </getecma-button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="vehicleId" id="app">
            <vue-html2pdf
              ref="html2PdfMonth"
              :show-layout="false"
              :float-layout="true"
              :enable-download="true"
              :preview-modal="true"
              :paginate-elements-by-height="1400"
              :filename="titleMonthPdf"
              :pdf-quality="2"
              :manual-pagination="true"
              pdf-format="a4"
              pdf-orientation="landscape"
              pdf-content-width="1124px">
              <pdf-content-month slot="pdf-content" :calendar-options="calendarOptionsPrintMonth" />
            </vue-html2pdf>
            <vue-html2pdf
              ref="html2PdfDay"
              :show-layout="false"
              :float-layout="true"
              :enable-download="true"
              :preview-modal="true"
              :paginate-elements-by-height="1100"
              :filename="titleDayPdf"
              :pdf-quality="2"
              :manual-pagination="true"
              pdf-format="a4"
              pdf-orientation="portrait"
              pdf-content-width="794px">
              <pdf-content-day slot="pdf-content" :calendar-options="calendarOptionsPrintDay" />
            </vue-html2pdf>
          </div>
          <full-calendar
            v-if="vehicleId"
            class="calendar-calendar"
            :options="calendarOptions">
            <template #eventContent="arg">
              <i>{{ arg.event.title }}</i>
            </template>
          </full-calendar>
          <!-- MODALS -->
          <getecma-modal-add-collect
            v-if="isShowAddCollectModal"
            ref="modalAdd"
            :calendar-start="selectCalendarStart"
            :calendar-end="selectCalendarEnd"
            :calendar-all-day="selectCalendarAllDay"
            :vehicle-id="vehicleId"
            @close="closeModals"
            @onAddCollect="onAddCollect">
          </getecma-modal-add-collect>
          <getecma-modal-edit-collect
            v-if="isShowEditCollectModal"
            ref="modalEdit"
            @close="closeModals"
            @onEditCollect="onEditCollect()">
          </getecma-modal-edit-collect>
          <getecma-modal-generate-collects
            v-if="isShowGenerateCollectsModal"
            ref="modalGenerate"
            @close="closeModals"
            @onGenerateCollects="onGenerateCollects()">
          </getecma-modal-generate-collects>
        </div>
      </div>
    </template>
  </getecma-aside-content-layout>
</template>

<script>
import { isMinLayout } from '@/helpers/layout/layout';
import { getters } from '@/modules/user/user.store';
import { USERS_ROLES } from '@/modules/user/user.constants';
import { fetchVehicles } from '@/modules/vehicle/vehicle.service';
import { holidays } from '@/modules/home/home.constants';
import { toastError, toastSuccess } from '@/services/toastService';
import { Tooltip } from 'bootstrap';

import { createNewCollect, fetchCollects, getCollectById, removeCollect, updateCollectInformation, fetchHolidays } from '@/modules/home/home.service';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';
import VueHtml2pdf from '@/components/html2pdf/VueHtml2pdf.vue';
import PdfContentMonth from '@/components/html2pdf/PdfContentMonth.vue';
import PdfContentDay from '@/components/html2pdf/PdfContentDay.vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import FullCalendar from '@fullcalendar/vue';
import ptbrLocale from '@fullcalendar/core/locales/pt-br';
// eslint-disable-next-line import/no-extraneous-dependencies
import dayGridPlugin from '@fullcalendar/daygrid';
// eslint-disable-next-line import/no-extraneous-dependencies
import timeGridPlugin from '@fullcalendar/timegrid';
// eslint-disable-next-line import/no-extraneous-dependencies
import interactionPlugin from '@fullcalendar/interaction';
import GetecmaAsideContentLayout from '@/layouts/AsideContentLayout.vue';

export default {
  name: 'GetecmaHomeLayout',
  components: {
    GetecmaAsideContentLayout,
    GetecmaBreadcrumb,
    FullCalendar,
    VueHtml2pdf,
    PdfContentMonth,
    PdfContentDay,
  },
  provide() {
    const collectVm = {};
    Object.defineProperty(collectVm, 'collect', {
      get: () => this.collect,
    });
    return { collectVm };
  },
  data() {
    return {
      isLoading: false,
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
      ],
      paginatePdf: null,
      filenamePdf: null,
      orientationPdf: null,
      contentWidthPdf: null,
      isShowAddCollectModal: false,
      isShowEditCollectModal: false,
      isShowGenerateCollectsModal: false,
      selectCalendarStart: null,
      selectCalendarEnd: null,
      selectCalendarAllDay: false,
      calendarApi: null,
      calendarClickInfo: null,
      collect: {
        id: null,
        title: null,
        vehicle_id: null,
        customer_id: null,
        group_id: null,
        employees: [],
        color: '#ffffff',
        start: null,
        end: null,
        allDay: null,
        delete: false,
      },
      calendarOptionsPrintDay: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
        ],
        headerToolbar: false,
        initialView: 'dayGridDay',
        validRange: {
          start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString(),
          end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString(),
        },
        displayEventTime: false,
        events: [],
        dayHeaders: false,
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: false,
        locale: ptbrLocale,
      },
      calendarOptionsPrintMonth: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
        ],
        headerToolbar: false,
        initialView: 'dayGridMonth',
        validRange: {
          start: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString(),
          end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1).toISOString(),
        },
        displayEventTime: false,
        events: [],
        dayHeaders: false,
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: false,
        locale: ptbrLocale,
      },
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek,dayGridDay',
        },
        initialView: 'dayGridMonth',
        validRange: {
          start: new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString(),
          end: new Date(new Date().setMonth(new Date().getMonth() + 3)).toISOString(),
        },
        eventDidMount: this.eventHover,
        displayEventTime: false,
        events: [],
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: false,
        select: this.showAddCollectModal,
        eventClick: this.showEditCollectModal,
        eventsSet: this.handleEvents,
        locale: ptbrLocale,
        eventChange: this.editCollect,
        eventRemove: this.delCollect,
      },
      months: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
      currentEvents: [],
      vehicles: [],
      municipalHolidays: [],
      nationalHolidays: [],
      vehicleId: null,
      vehicleLicensePlate: null,
    };
  },
  computed: {
    isAdmin() {
      return getters.getUser().permission === USERS_ROLES.ADMIN;
    },
    titleDayPdf() {
      const now = new Date();
      const monthId = now.getMonth();
      const monthName = this.months[monthId];
      const day = now.getDate();
      const year = now.getFullYear();
      return `${this.vehicleLicensePlate} - ${day}-${monthName}-${year}`;
    },
    titleMonthPdf() {
      const now = new Date();
      const monthId = now.getMonth();
      const monthName = this.months[monthId];
      const year = now.getFullYear();
      return `${this.vehicleLicensePlate} - ${monthName}-${year}`;
    },
  },
  async mounted() {
    if (getters.getUser().permission !== USERS_ROLES.ADMIN) {
      this.calendarOptions.editable = false;
      this.calendarOptions.selectable = false;
    }
    await this.fetchHolidays(new Date().getFullYear());
    await this.fetchHolidays(new Date().getFullYear() + 1);
    this.nationalHolidays.push(...holidays(new Date().getFullYear()));
    this.nationalHolidays.push(...holidays(new Date().getFullYear() + 1));
    await this.fetchVehicles();
  },
  methods: {
    isMinLayout,
    sleep(milliseconds) {
      return new Promise(resolve => setTimeout(resolve, milliseconds));
    },
    async downloadPdfMonth() {
      this.$refs.html2PdfMonth.generatePdf();
    },
    async downloadPdfDay() {
      this.$refs.html2PdfDay.generatePdf();
    },
    eventHover(info) {
      new Tooltip(info.el, {
        title: info.event.extendedProps.description,
        placement: 'top',
        trigger: 'hover',
        container: 'body',
      });
    },
    async fetchHolidays(year) {
      try {
        const response = await fetchHolidays();
        this.municipalHolidays.push(...response.rows.map(holiday => ({
          start: `${year}${holiday.citys_anniversary.substring(4, 10)}T00:00:00-03:00`,
          allDay: true,
          overlap: false,
          display: 'background',
        })));
      } catch (error) {
        console.error('Erro ao buscar os feriados:', error);
      }
    },
    async fetchVehicles() {
      try {
        const params = { limit: 'all', page: 1, search: '' };
        const response = await fetchVehicles(params);
        this.vehicles = response.rows.map(vehicle => ({
          key: vehicle.id,
          value: vehicle.license_plate,
        }));
      } catch (error) {
        console.error('Erro ao buscar os veículos:', error);
      }
    },
    onVehicleChange(vehicle) {
      this.vehicleId = vehicle.key;
      this.vehicleLicensePlate = vehicle.value;
      this.fetchCollects(this.vehicleId);
    },
    async fetchCollects(vehicleId) {
      fetchCollects(vehicleId)
        .then(collects => {
          this.calendarOptionsPrintDay.events = collects.rows;
          this.calendarOptionsPrintDay.events.push(...this.nationalHolidays);
          this.calendarOptionsPrintDay.events.push(...this.municipalHolidays);
          this.calendarOptionsPrintMonth.events = collects.rows;
          this.calendarOptionsPrintMonth.events.push(...this.nationalHolidays);
          this.calendarOptionsPrintMonth.events.push(...this.municipalHolidays);
          this.calendarOptions.events = collects.rows;
          this.calendarOptions.events.push(...this.nationalHolidays);
          this.calendarOptions.events.push(...this.municipalHolidays);
        })
        .catch(() => toastError('Erro ao obter coletas'));
    },
    showAddCollectModal(selectInfo) {
      this.calendarApi = selectInfo.view.calendar;
      this.selectCalendarStart = selectInfo.startStr;
      this.selectCalendarEnd = selectInfo.endStr;
      this.selectCalendarAllDay = selectInfo.allDay;
      document.documentElement.style.overflow = 'hidden';
      this.isShowAddCollectModal = true;
    },
    async showEditCollectModal(clickInfo) {
      if (getters.getUser().permission !== USERS_ROLES.ADMIN) {
        this.isShowEditCollectModal = false;
      } else {
        this.calendarClickInfo = clickInfo;
        await getCollectById(clickInfo.event.id)
          .then(data => {
            this.collect = {
              id: data.id,
              title: data.title,
              vehicle_id: data.vehicle_id,
              customer_id: data.customer_id,
              group_id: data.group_id,
              employees: [],
              color: '#ffffff',
              start: data.start,
              end: data.end,
              allDay: null,
              delete: false,
            };
          })
          .catch(() => toastError('Erro ao obter coleta por ID'));
        document.documentElement.style.overflow = 'hidden';
        this.isShowEditCollectModal = true;
      }
    },
    showGenerateCollectsModal() {
      document.documentElement.style.overflow = 'hidden';
      this.isShowGenerateCollectsModal = true;
    },
    async onAddCollect() {
      await this.addCollect(this.collect);
      await this.fetchCollects(this.vehicleId);
      this.collect = {
        id: null,
        title: null,
        vehicle_id: null,
        customer_id: null,
        group_id: null,
        employees: [],
        color: '#ffffff',
        start: null,
        end: null,
        allDay: null,
        delete: false,
      };
    },
    async onEditCollect() {
      if (this.collect.delete) {
        this.calendarClickInfo.event.remove();
      } else {
        await getCollectById(this.collect.id)
          .then(data => {
            this.collect = {
              id: data.id,
              title: data.title,
              vehicle_id: this.collect.vehicle_id,
              customer_id: data.customer_id,
              group_id: data.group_id,
              employees: this.collect.employees,
              color: '#ffffff',
              start: data.start,
              end: data.end,
              allDay: null,
              delete: false,
            };
          })
          .catch(() => toastError('Erro ao obter coleta por ID'));

        await updateCollectInformation(this.collect)
          .then(() => { toastSuccess('Coleta editada!'); })
          .catch(() => { toastError('Erro ao atualizar a coleta'); });
        await this.fetchCollects(this.vehicleId);
      }
      this.collect = {
        id: null,
        title: null,
        vehicle_id: null,
        customer_id: null,
        group_id: null,
        employees: [],
        color: '#ffffff',
        start: null,
        end: null,
        allDay: null,
        delete: false,
      };
    },
    async onGenerateCollects() {
      await this.fetchCollects(this.vehicleId);
    },
    addCollect(collect) {
      createNewCollect(collect)
        .then(() => { toastSuccess('Coleta adicionada!'); })
        .catch(() => {
          toastError('Erro ao salvar a coleta');
        });
    },
    async editCollect(eventData) {
      await getCollectById(eventData.event.id)
        .then(data => {
          this.collect = {
            id: data.id,
            title: data.title,
            vehicle_id: data.vehicle_id,
            customer_id: data.customer_id,
            group_id: data.group_id,
            employees: null,
            color: '#ffffff',
            start: eventData.event.startStr,
            end: eventData.event.endStr,
            allDay: null,
            delete: false,
          };
        })
        .catch(() => toastError('Erro ao obter coleta por ID'));

      await updateCollectInformation(this.collect)
        .then(() => { toastSuccess('Coleta editada!'); })
        .catch(() => { toastError('Erro ao atualizar a coleta'); });
    },
    delCollect(eventData) {
      removeCollect(eventData.event.id)
        .then(() => { toastSuccess('Coleta excluída!'); });
    },
    handleEvents(events) {
      this.currentEvents = events;
    },
    closeModals() {
      this.isShowGenerateCollectsModal = false;
      this.isShowEditCollectModal = false;
      this.isShowAddCollectModal = false;
      document.documentElement.style.overflow = 'auto';
    },
  },
};
</script>

<style lang='css'>

  h2 {
    margin: 0;
    font-size: 16px;
  }

  ul {
    margin: 0;
    padding: 0 0 0 1.5em;
  }

  li {
    margin: 1.5em 0;
    padding: 0;
  }

  b { /* used for event dates/times */
    margin-right: 3px;
  }

  .fc .fc-toolbar.fc-header-toolbar {
    width: 100%;
  }

  .calendar {
    display: flex;
    overflow: auto;
    min-height: 100%;
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    font-size: 14px;
  }

  .calendar-sidebar {
    width: 300px;
    line-height: 1.5;
    background: #eaf9ff;
    border-right: 1px solid #d3e2e8;
  }

  .calendar-sidebar-section {
    padding: 2em;
  }

  .calendar-main {
    flex-grow: 1;
    padding: 3em;
  }

  .fc .fc-daygrid-day-frame {
    height: 396px;
  }

  .fc {
    max-width: 1700px;
    height: 100%!important;
    margin: 0 auto;
  }

  .fc .fc-scroller-liquid-absolute {
    position: unset;
  }

  .fc .fc-scrollgrid-liquid {
    padding-bottom: 30px;
  }

  .fc .fc-view-harness {
    height: 100%!important;
  }
</style>
